import React, { useState, useEffect } from 'react';

import CatCookies from "../assets/cat-cookies.svg"

const Wrapper = ({ children }) => {

    const [preload, setPreload] = useState(true);
    const [cookies, setCookies] = useState("0");

    const [cookiesHide, setCookiesHide] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPreload(false)
        }, 1000);

        if(localStorage.getItem("cookies") === "0" || localStorage.getItem("cookies") === "1") {
            if(localStorage.getItem("cookies") === "1") {
                setCookies("1");
            }
        } else {
            localStorage.setItem("cookies", "1");
            setCookies("1");
        }

    }, []);

    const hideCookies = () => {
        setCookiesHide(true);

        setTimeout(() => {
            localStorage.setItem("cookies", "0");
            setCookies("0");
            setCookiesHide(false);
        }, 400);
    }

    return (
        <>
        {preload ?
            <div className="preloader done">
                <p className="preloader__text">movadex</p>
            </div>
        : null}

        {cookies === "1" ? <div className={`cookies${cookiesHide ? " hide" : ""}`}>
            <div className="cookies__wrapper">
                <img className="cookies__cat" src={CatCookies} alt=""/>

                <p className="cookies__text">We use cookies to improve your experience and our services{"\n"}
                If you continue browsing, you will be providing your consent to our use of cookies. <span className="cookies__text_span">You can also check our <a className="cookies__link" href="/privacy-policy.html" target="_blank">Privacy Policy</a></span></p>

                <button className="cookies__button" onClick={hideCookies}>Confirm</button>
            </div>
        </div> : null}

        {children}
        </>
    );
}

export default Wrapper;
