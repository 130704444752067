/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

// import {MovaCat} from "../components/MovaCat"

function SEO({ description, title, img, preventIndex }) {
  const [url, setUrl] = useState(null)
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `,
  // )

  useEffect(() => {
    // console.log(MovaCat)
    setUrl(window.location.href)
    const test = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": "https://www.movadex.com/#organization",
          "name": "Movadex",
          "description": "Movadex, Best Web Developing Company in Ukraine, Website Design Company, Web Development Services, SEO, SMO, PPC.",
          "alternateName": "Movadex, software design and development",
          "url": "https://www.movadex.com/",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "nor@movadex.com",
            "contactType": "Sales & Support"
          },
          "sameAs": [
            "https://dribbble.com/movadex/",
            "https://www.facebook.com/movadex/",
            "https://twitter.com/movadex/",
            "https://www.linkedin.com/company/movadex/",
            "https://www.behance.net/movadex/",
            "https://www.instagram.com/movadex/"
          ],
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.movadex.com/#logo",
            "url": "https://www.movadex.com/mail/movadex-color.png",
            "width": 332,
            "height": 332,
            "caption": "Movadex Software Solutions."
          },
          "image": {
            "@id": "https://www.movadex.com/#logo"
          }
        },
        {
          "@type": "WebSite",
          "description": "Movadex, Best Web Developing Company in Ukraine, Website Design Company, Web Development Services, SEO, SMO, PPC.",
          "@id": "https://www.movadex.com/#WebSite",
          "url": "https://www.movadex.com/",
          "inLanguage": "en-US",
          "name": "Best Software design and development company in Ukraine.",
          "publisher": {
            "@id": "https://www.movadex.com/#organization",
          }
        }
      ]
    }
  }, [])

  const metaDescription = description || ""
  // site.siteMetadata.description

  return (<Helmet
    // htmlAttributes={{
    //   lang: en,
    // }}
    description={metaDescription}
    title={title}
    // titleTemplate={`%s | ${site.siteMetadata.title}`}
  >
    <script type="application/ld+json">
      {`
        {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": "https://www.movadex.com/#organization",
          "name": "Movadex",
          "description": "Movadex, Best Web Developing Company in Ukraine, Website Design Company, Web Development Services, SEO, SMO, PPC.",
          "alternateName": "Movadex, software design and development",
          "url": "https://www.movadex.com/",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "nor@movadex.com",
            "contactType": "Sales & Support"
          },
          "sameAs": [
            "https://dribbble.com/movadex/",
            "https://www.facebook.com/movadex/",
            "https://twitter.com/movadex/",
            "https://www.linkedin.com/company/movadex/",
            "https://www.behance.net/movadex/",
            "https://www.instagram.com/movadex/"
          ],
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.movadex.com/#logo",
            "url": "https://www.movadex.com/mail/movadex-color.png",
            "width": 332,
            "height": 332,
            "caption": "Movadex Software Solutions."
          },
          "image": {
            "@id": "https://www.movadex.com/#logo"
          }
        },
        {
          "@type": "WebSite",
          "description": "Movadex, Best Web Developing Company in Ukraine, Website Design Company, Web Development Services, SEO, SMO, PPC.",
          "@id": "https://www.movadex.com/#WebSite",
          "url": "https://www.movadex.com/",
          "inLanguage": "en-US",
          "name": "Best Software design and development company in Ukraine.",
          "publisher": {
            "@id": "https://www.movadex.com/#organization"
          }
        }
      ]
    }
      `}
    </script>

    <script src="https://www.googletagmanager.com/gtag/js?id=AW-770564991" />
    {preventIndex ? <meta name="robots" content="noindex"/> : null }
      <meta charSet="UTF-8" />
    <title>{title}</title>
    <meta name="og:title" property="og:title" content={title} />
    <meta name="twitter:title" property="twitter:title" content={title} />

    <meta name="googlebot" content="noindex, nofollow" />
    <meta name="robots" content="noindex, nofollow" />

    <meta name="description" property="description" content={metaDescription} />
    <meta name="og:description" property="og:description" content={metaDescription} />
    <meta name="twitter:description" property="twitter:description" content={metaDescription} />
    <meta name="og:image" property="og:image"
          content={img ? "https://www.movadex.com" + img : "https://www.movadex.com/images/movadex-preview.png"} />
    <meta name="twitter:image" property="twitter:image"
          content={img ? "https://www.movadex.com" + img : "https://www.movadex.com/images/movadex-preview.png"} />
    {/*<meta name="og:url" property="og:url" content={url} />*/}
    <meta name="twitter:card" property="twitter:card" content="summary_large_image" />

    {/*OLD SEO*/}
    {/*<meta property="og:title" content={title} />*/}
    {/*{metaDescription && <meta name="description" property="og:description" content={metaDescription}/>}*/}
    {/*{metaDescription && <meta name="description" content={metaDescription}/>}*/}
    {/*{metaDescription && <meta  property="og:description" content={metaDescription} />}*/}

    {/*{url && <meta name="og:url" property="og:url" content={url} />}*/}
    {/*<meta property="og:image" content={img ? 'https://www.movadex.com' + img : 'https://www.movadex.com/images/movadex-preview.png'} />*/}
    {/**/}
    {/*<meta name="twitter:title" content={title} />*/}
    {/*<meta name="twitter:card" content="summary_large_image"/>*/}
    {/*<meta name="twitter:image" content={img ? 'https://www.movadex.com' + img : 'https://www.movadex.com/images/movadex-preview.png'} />*/}
    {/*{metaDescription && <meta name="twitter:description" content={metaDescription} />}*/}

  </Helmet>)
}

// SEO.defaultProps = {
// lang: `en`,
// meta: [],
// description: ``,
// }

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
