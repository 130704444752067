import React from 'react';
import logoNew from '../assets/logo-new.svg'
import logoNewWhite from '../assets/logo-white-new.svg'

const movadexLogo = props => {
    return (
        <div className={`movadexLogo${props.theme === "dark" ? " nav-dark" : ""}`}>
            {/*<img src={logoNewWhite} alt="" />*/}
            {/*<img className="show-on-scroll" src={logoNew} alt="" />*/}

          <svg className="movadexLogo__icon" width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.2374 24.4767L16.9627 29.3401L8.48047 24.3615L12.6985 22.0104L14.1967 24.5459L16.9627 19.6824L22.5867 19.6133L21.2038 22.0565L25.2374 24.4767Z" fill="#032A80"/>
            <path d="M33.6983 9.77296L25.2391 14.7055L16.9644 9.77296L8.48219 14.7055L0 9.81906L8.50524 4.88648L16.9644 0L25.4235 4.88648L33.6983 9.77296Z" fill="#BDD2FF"/>
            <path d="M16.9653 9.7961L16.827 9.86525L16.7578 9.81915L16.8961 9.75L16.9653 9.7961Z" fill="#BDD2FF"/>
            <path d="M16.9653 9.7961L16.827 9.86525L16.7578 9.81915L16.8961 9.75L16.9653 9.7961Z" fill="#BDD2FF"/>
            <path d="M16.9644 29.3412V39.1142L8.36695 34.1125L0 29.2951L0.0921978 19.5913L0 9.81836L8.48219 14.7048V24.3625L8.50524 24.3856L16.9644 29.3412Z" fill="#005AEC"/>
            <path d="M16.9644 29.3417V39.1147L8.36695 34.1129L0 29.2956L0.0921978 19.5918L8.48219 24.363L8.50524 24.3861L16.9644 29.3417Z" fill="#FFC500"/>
            <path d="M8.50411 24.3635V24.3871L8.48047 24.3635V24.3398L8.50411 24.3635Z" fill="#1958E3"/>
            <path d="M14.0584 14.7732L16.9627 19.6827L14.1967 24.5462L12.6985 22.0107L8.48047 24.3618V14.7041L16.9627 9.77148L16.9396 14.7732H14.0584Z" fill="#0038B2"/>
            <path d="M19.6664 14.7973H19.6191L19.6428 14.75L19.6664 14.7973Z" fill="#1958E3"/>
            <path d="M19.6664 14.7973H19.6191L19.6428 14.75L19.6664 14.7973Z" fill="#1958E3"/>
            <path d="M25.2395 14.7041V24.477L21.2066 22.0554L22.5888 19.6136L16.9648 19.6827L14.0605 14.7732H16.9417L16.9648 9.77148L25.2395 14.7041Z" fill="#1958E3"/>
            <path d="M16.9648 19.6848L17.0109 19.6387L17.1031 19.6848H16.9648Z" fill="#1958E3"/>
            <path d="M33.697 9.77539L33.6066 29.2909L25.3318 34.1774V34.2235L16.9648 39.117V29.319L25.2396 24.3583V14.7017L33.697 9.77539Z" fill="#75A0FE"/>
            <path d="M33.6066 20V29.2909L25.3318 34.1774V34.2235L16.9648 39.117V29.319L25.2396 24.3583L33.6066 20Z" fill="#FFDF00"/>
          </svg>

          <svg className="movadexLogo__text" width="150" height="28" viewBox="0 0 150 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.1921 7.78173C23.3564 7.78173 25.0803 8.48639 26.3638 9.89572C27.6725 11.305 28.3268 13.1925 28.3268 15.5582V27.1851H23.4571V15.8979C23.4571 14.7654 23.1802 13.8846 22.6266 13.2555C22.0729 12.6263 21.2927 12.3117 20.2861 12.3117C19.1787 12.3117 18.3105 12.6766 17.6813 13.4065C17.0773 14.1363 16.7753 15.1933 16.7753 16.5774V27.1851H11.9056V15.8979C11.9056 14.7654 11.6288 13.8846 11.0751 13.2555C10.5214 12.6263 9.74128 12.3117 8.73462 12.3117C7.65246 12.3117 6.78421 12.6766 6.12988 13.4065C5.50072 14.1363 5.18614 15.1933 5.18614 16.5774V27.1851H0.316406V8.31022H5.18614V10.311C6.31863 8.62481 8.06771 7.78173 10.4334 7.78173C12.7487 7.78173 14.46 8.68772 15.5673 10.4997C16.8257 8.68772 18.7006 7.78173 21.1921 7.78173Z"/>
                <path d="M47.0342 24.8447C45.0964 26.7573 42.7307 27.7136 39.9372 27.7136C37.1437 27.7136 34.7781 26.7573 32.8403 24.8447C30.9276 22.9068 29.9713 20.5412 29.9713 17.7477C29.9713 14.9542 30.9276 12.6011 32.8403 10.6885C34.7781 8.75064 37.1437 7.78173 39.9372 7.78173C42.7307 7.78173 45.0964 8.75064 47.0342 10.6885C48.972 12.6011 49.9409 14.9542 49.9409 17.7477C49.9409 20.5412 48.972 22.9068 47.0342 24.8447ZM36.2755 21.4849C37.257 22.4664 38.4776 22.9572 39.9372 22.9572C41.3969 22.9572 42.6175 22.4664 43.599 21.4849C44.5805 20.5034 45.0712 19.2577 45.0712 17.7477C45.0712 16.2377 44.5805 14.9919 43.599 14.0104C42.6175 13.029 41.3969 12.5382 39.9372 12.5382C38.4776 12.5382 37.257 13.029 36.2755 14.0104C35.3192 14.9919 34.841 16.2377 34.841 17.7477C34.841 19.2577 35.3192 20.5034 36.2755 21.4849Z"/>
                <path d="M63.5391 8.31022H68.8996L61.7271 27.1851H56.1779L49.0054 8.31022H54.3659L58.9337 21.5227L63.5391 8.31022Z"/>
                <path d="M83.3454 8.31022H88.2151V27.1851H83.3454V24.9579C81.8858 26.7951 79.8347 27.7136 77.1922 27.7136C74.6755 27.7136 72.5112 26.7573 70.6992 24.8447C68.9124 22.9068 68.019 20.5412 68.019 17.7477C68.019 14.9542 68.9124 12.6011 70.6992 10.6885C72.5112 8.75064 74.6755 7.78173 77.1922 7.78173C79.8347 7.78173 81.8858 8.7003 83.3454 10.5375V8.31022ZM74.3609 21.5982C75.3424 22.5797 76.5882 23.0704 78.0982 23.0704C79.6082 23.0704 80.8539 22.5797 81.8354 21.5982C82.8421 20.5915 83.3454 19.308 83.3454 17.7477C83.3454 16.1874 82.8421 14.9164 81.8354 13.9349C80.8539 12.9283 79.6082 12.425 78.0982 12.425C76.5882 12.425 75.3424 12.9283 74.3609 13.9349C73.3794 14.9164 72.8887 16.1874 72.8887 17.7477C72.8887 19.308 73.3794 20.5915 74.3609 21.5982Z"/>
                <path d="M105.428 0.760254H110.298V27.1851H105.428V24.9579C103.994 26.7951 101.955 27.7136 99.3127 27.7136C96.7709 27.7136 94.594 26.7573 92.782 24.8447C90.9952 22.9068 90.1018 20.5412 90.1018 17.7477C90.1018 14.9542 90.9952 12.6011 92.782 10.6885C94.594 8.75064 96.7709 7.78173 99.3127 7.78173C101.955 7.78173 103.994 8.7003 105.428 10.5375V0.760254ZM96.4437 21.5982C97.4504 22.5797 98.7087 23.0704 100.219 23.0704C101.729 23.0704 102.974 22.5797 103.956 21.5982C104.937 20.5915 105.428 19.308 105.428 17.7477C105.428 16.1874 104.937 14.9164 103.956 13.9349C102.974 12.9283 101.729 12.425 100.219 12.425C98.7087 12.425 97.4504 12.9283 96.4437 13.9349C95.4622 14.9164 94.9715 16.1874 94.9715 17.7477C94.9715 19.308 95.4622 20.5915 96.4437 21.5982Z"/>
                <path d="M117.356 19.7484C118.011 22.1141 119.785 23.2969 122.679 23.2969C124.541 23.2969 125.951 22.6677 126.907 21.4094L130.833 23.6744C128.971 26.3672 126.227 27.7136 122.603 27.7136C119.483 27.7136 116.979 26.7699 115.091 24.8824C113.204 22.9949 112.26 20.6167 112.26 17.7477C112.26 14.9039 113.191 12.5382 115.054 10.6507C116.916 8.73805 119.307 7.78173 122.226 7.78173C124.994 7.78173 127.272 8.73805 129.059 10.6507C130.871 12.5634 131.777 14.929 131.777 17.7477C131.777 18.3768 131.714 19.0438 131.588 19.7484H117.356ZM117.281 15.9734H126.907C126.63 14.6899 126.051 13.7336 125.17 13.1045C124.315 12.4753 123.333 12.1607 122.226 12.1607C120.917 12.1607 119.835 12.5005 118.979 13.18C118.124 13.8343 117.558 14.7654 117.281 15.9734Z"/>
                <path d="M150 27.1851H144.375L140.298 21.4472L136.183 27.1851H130.559L137.467 17.5589L130.861 8.31022H136.485L140.298 13.6329L144.111 8.31022H149.698L143.092 17.5212L150 27.1851Z"/>
            </svg>
        </div>
    );
}

export default movadexLogo;
