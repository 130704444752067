import { useState, useEffect } from "react"

export const useMediaQuery = query => {
  let mediaMatch = undefined
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      mediaMatch = window.matchMedia(query)
      setMatches(mediaMatch.matches)
    }

    const handler = e => setMatches(e.matches)
    mediaMatch.addListener(handler)
    return () => mediaMatch.removeListener(handler)
  })
  return matches
}