import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import MovadexLogo from "../components/movadexLogo"

import { useMediaQuery } from "../components/isMediaSmall"

import scrollTo from 'gatsby-plugin-smoothscroll';

const Header = () => {
  const [scrolled, setScrolled] = useState(false)
  const [headerDark, setHeaderDark] = useState(false)

  const [caseTheme, setCaseTheme] = useState("")

  const [navOpen, setNavOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    const regex = /(\/career\/)|(\/portfolio\/)|(\/blog\/)$|(\/services\/)|(\/about\/)|(\/our-team\/)|(\/survey\/)/

    if (regex.test(window.location.pathname)) {
      setHeaderDark(true)
    } else {
      setHeaderDark(false)
    }

    switch (window.location.pathname.split("/")[2]) {
      case "flight-simulator":
        setCaseTheme("flight-simulator")
        break

      case "turtlequeue":
        setCaseTheme("turtlequeue")
        break

      case "startify":
        setCaseTheme("startify")
        break

      case "medicus-ug":
        setCaseTheme("medicus-ug")
        break

      case "kyiv-metro-app":
        setCaseTheme("kyiv-metro-app")
        break

      case "uprice":
        setCaseTheme("uprice")
        break

      case "znaj-ua":
        setCaseTheme("znaj-ua")
        break

      default:
        break
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  const isMediaSmall = useMediaQuery("(max-width: 1300px)")

  const handleNav = () => {
    if(isMediaSmall) {
      setNavOpen(!navOpen);
    }
  }

  return (
    <header
      className={`header${isMediaSmall ? "" : headerDark ? " dark" : " light"}${
        scrolled && !navOpen ? " scrolled" : ""
      }${
        !isMediaSmall && !scrolled && caseTheme
          ? ` header-cases header-cases_${caseTheme}`
          : ""
      }${isMediaSmall && navOpen ? " header-open" : ""}`}
    >
      {isMediaSmall ? (
        <div className="header-burger">
          <Link to="/" onClick={navOpen ? handleNav : null}>
            <MovadexLogo theme={!navOpen && (headerDark || scrolled) ? "dark" : ""} />
          </Link>

          <div className={`header-burger__wrapper${!navOpen && (headerDark || scrolled) ? " header-burger__wrapper_dark" : ""}`} onClick={handleNav}>
            <span className="header-burger__item"/>
            <span className="header-burger__item"/>
            <span className="header-burger__item"/>
          </div>
        </div>
      ) : null}

      <nav className="nav container">
        {isMediaSmall ? null : (
          <Link to="/">
            <MovadexLogo theme={headerDark || scrolled ? "dark" : ""} />
          </Link>
        )}

        <ul className="nav-list">
          <li className="nav-list__item">
            <Link to="/about/" className="nav-list__link" onClick={handleNav}>
              About us
            </Link>
          </li>
          <li className="nav-list__item">
            <Link to="/portfolio/" className="nav-list__link" onClick={handleNav}>
              Portfolio
            </Link>
          </li>

          <li className="nav-list__item">
            <Link to="/survey/" className="nav-list__link" onClick={handleNav}>
              Project request
            </Link>
          </li>

          <li className="nav-list__item">
            <Link to="/career/" className="nav-list__link" onClick={handleNav}>
              We are hiring
            </Link>
          </li>

          <li className="nav-list__item">
            <Link to="/blog/" className="nav-list__link" onClick={handleNav}>
              Blog
            </Link>
          </li>

          {/*<li className="nav-list__item">*/}
          {/*  <Link to="/our-team/" className="nav-list__link" onClick={handleNav}>*/}
          {/*    Our team*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </ul>

        <div className="nav__button" onClick={() => {
          handleNav();
          setScrolled(true);
          scrollTo('#footer-mail')
        } }>
          Contact us
        </div>

        {isMediaSmall ? (
          <>
            <a className="nav__contact" href="mailto:nor@movadex.com">
              nor@movadex.com
            </a>

            <a className="nav__contact" href="tel:+19292188640">
              +1 929-218-8640
            </a>
          </>
        ) : null}
      </nav>
    </header>
  )
}

export default Header
